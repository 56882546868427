/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Divider, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Next to come"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-141vi5 --style2 --left --full" name={"[[UNIsectionname1]]"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/503a46605c194a70b992339c7ed90d30_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/503a46605c194a70b992339c7ed90d30_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/503a46605c194a70b992339c7ed90d30_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/503a46605c194a70b992339c7ed90d30_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/503a46605c194a70b992339c7ed90d30_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/503a46605c194a70b992339c7ed90d30_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/503a46605c194a70b992339c7ed90d30_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/503a46605c194a70b992339c7ed90d30_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--102" style={{"maxWidth":""}} content={"<span style=\"color: white;\">next to come...</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3" name={"ai"} style={{"paddingBottom":0,"paddingTop":83}} anim={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow4" content={"AI powered features<br>"}>
              </Title>

              <Image className="--shadow4" style={{"maxWidth":473}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/38872/a62d259b649a4414b33b534b609d9732_s=350x_.png 350w, https://cdn.swbpg.com/t/38872/a62d259b649a4414b33b534b609d9732_s=660x_.png 660w, https://cdn.swbpg.com/t/38872/a62d259b649a4414b33b534b609d9732_s=860x_.png 860w, https://cdn.swbpg.com/t/38872/a62d259b649a4414b33b534b609d9732_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/38872/a62d259b649a4414b33b534b609d9732_s=860x_.png"}>
              </Image>

              <Divider style={{"marginBottom":15,"marginTop":33}}>
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --center" style={{"paddingTop":53,"paddingBottom":112}} name={"axlj0s62qgg"} anim={"5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" style={{"maxWidth":708}} content={"<span style=\"color: var(--black);\">and more</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--02" name={"bottom"} style={{"backgroundColor":"rgba(192, 192, 192, 1)","marginTop":38,"paddingTop":38,"paddingBottom":0}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1291,"marginBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="--center mb--02 mt--02" style={{"paddingTop":0,"paddingBottom":0,"paddingLeft":0,"paddingRight":0}}>
              
              <Title className="title-box title-box--style2 title-box--center fs--43 swpf--uppercase" content={"<a href=\"/contact\">Contact</a>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":334}} content={"www.smartmedicalpad.com<br>info@smartmedicalpad.com"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(224, 224, 224, 1)","marginTop":0}} layout={"l1"} name={"kh8dvsp8m4"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--style5 title-box--center fs--26" style={{"paddingTop":8,"paddingBottom":6}} content={"Disclaimer"}>
              </Title>

              <Text className="text-box text-box--center fs--18 mt--0" content={"All patient names and data showed in all pictures&nbsp;on this website are completely imaginary and not real. It serves only to demonstrative purposes."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}